<template>
  <div class="phone-number-input">
    <v-text-field
      class="pt-0"
      v-model="inputValue"
      clearable
      counter
      maxlength="10"
      type="tel"
      :label="label"
      :outlined="outlined"
      :dense="dense"
      :required="required"
      :rules="[...rules, phoneNumberRule]"
    />
  </div>
</template>

<script>
import PhoneNumber from "awesome-phonenumber";

export default {
  name: "PhoneNumberInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      regionCode: "TW",
      inputValue: "",
      phoneNumberRule: (v) =>
        new PhoneNumber(v, this.regionCode).isValid() ||
        this.$t("__phone_number_invalid"),
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val?.length > 0) {
          const phoneNumber = new PhoneNumber(val);
          this.regionCode = phoneNumber.getRegionCode();
          const inputtedPhoneNumber = new PhoneNumber(
            this.inputValue,
            this.regionCode
          );
          if (phoneNumber.getNumber() !== inputtedPhoneNumber.getNumber()) {
            this.inputValue = phoneNumber
              .getNumber("national")
              .replaceAll(" ", "");
          }
        } else {
          this.inputValue = "";
        }
      },
    },
    inputValue: {
      handler(val) {
        const phoneNumber = new PhoneNumber(val, this.regionCode);
        this.$emit("input", phoneNumber.getNumber());
      },
    },
  },
};
</script>